import React from 'react';
import ShareIcon from '@mui/icons-material/Share';
import { Button } from '@mui/material';

const ShareGroceries = ({ groceries, title, groceryTypes, language }) => {
  const formatGroceryList = (forEmail = false) => {
    const list = groceries
      .map(section => {
        const marketName = groceryTypes?.find(g => g.id === section.market)?.description || 'Other';
        const items = section.groceries
          .map(item => `${item.description[language]}: ${item.quantity}g`)
          .join('\n');
        return `${marketName}:\n${items}`;
      })
      .join('\n\n');

    if (forEmail) {
      const date = new Date().toLocaleDateString('en-GB');
      return `Subject: ${title} - ${date}\n\n${list}`;
    }
    return list;
  };

  const handleShare = async () => {
    const text = formatGroceryList();
    const date = new Date().toLocaleDateString('en-GB');
    if (navigator.share) {
      try {
        const subjectTitle = title ? `${title} - ${date}` : 'Grocery list - ' + date;
        const textToShare = text ? text : `Grocery List Loading...`;
        await navigator.share({
          title: subjectTitle,
          subject: subjectTitle,
          text: textToShare
        });
      } catch (err) {
        if (err.name !== 'AbortError') {
          console.error('Error sharing:', err);
          await fallbackShare();
        }
      }
    } else {
      await fallbackShare();
    }
  };

  const fallbackShare = async () => {
    try {
      const emailText = formatGroceryList(true);
      await navigator.clipboard.writeText(emailText);
      alert('Grocery list copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy:', err);
      alert('Failed to share grocery list');
    }
  };

  return (
    <Button
      aria-label="Share grocery list"
      role="button"
      onClick={handleShare}
      variant="contained"
      sx={{
        position: 'fixed',
        bottom: '32px',
        right: '32px',
        borderRadius: '50%',
        minWidth: 'unset',
        width: '56px',
        height: '56px',
        backgroundColor: '#FAA72D',
        zIndex: 1000,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        '&:hover': {
          backgroundColor: '#E89819'
        }
      }}
    >
      <ShareIcon />
    </Button>
  );
};

export default ShareGroceries;