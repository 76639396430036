import React from 'react';
import { Dialog, Button } from '@mui/material';
import Calendar from "../../components/Calendar";
import {useStoryblok} from "../../services/storyblokService";

const WeeklyPlanDialog = ({
                          open,
                          onClose,
                          onApply,
                          selectedTime = {},
                          onTimeChange
                        }) => {

  const SB_weeklyPlan = useStoryblok("weekly-plan");

  const handleClose = () => {
    onClose();
  };

  const handleApply = () => {
    onApply();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
    >
      <div style={{ marginTop: 30, textAlign: "center", width: "100%" }}>
        <Calendar
          onChangeMealTime={(date, mealTime) => onTimeChange(date, mealTime)}
        />
      </div>
      <div
        style={{
          display: "flex",
          gap: "10px",
          margin: "20px",
          justifyContent: "flex-end"
        }}
      >
        <Button
          variant="outlined"
          onClick={handleClose}
        >
          {SB_weeklyPlan?.content?.Close ? SB_weeklyPlan?.content?.Close : "Close"}
        </Button>
        <Button
          variant="contained"
          onClick={handleApply}
          disabled={Object.keys(selectedTime).length === 0}
        >
          {SB_weeklyPlan?.content?.AddToPlan ? SB_weeklyPlan?.content?.AddToPlan : "Add to Plan"}
        </Button>
      </div>
    </Dialog>
  );
};

export default WeeklyPlanDialog;